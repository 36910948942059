<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('minutes')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.minutes"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('record_limits')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.record_limits"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          show-empty
          :items="items"
          :fields="fields"
          class="mb-0 game-history-table"
        >
          <template #head(account)>
            <span v-text="$t('account')" />
          </template>
          <template #head(amount)>
            <span v-text="$t('amount')" />
          </template>>
          <template #head(before)>
            <span v-text="$t('before')" />
          </template>
          <template #head(after)>
            <span v-text="$t('after')" />
          </template>
          <template #head(memo)>
            <span v-text="$t('memo')" />
          </template>
          <template #head(audit_result)>
            <span v-text="$t('audit_result')" />
          </template>
          <template #head(created_at)>
            <span v-text="$t('created_at')" />
          </template>
          <!-- Column: amount -->
          <template #cell(amount)="data">
            <span
              class="align-middle ml-50"
              v-text="Number(data.item.amount).toLocaleString()"
            />
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'

import Game from '@/models/Game'
import { EventBus } from '../../../eventbus'

export default {
  name: 'Audit',
  components: {
    BOverlay,
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
  },
  data() {
    return {
      items: [],
      loading: true,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        minutes: 35,
        record_limits: 35,
      },
      fields: [
        'id',
        'account',
        'before',
        'amount',
        'after',
        'memo',
        'audit_result',
        'created_at',
      ],
      perPage: 50,
      totalPage: 0,
    }
  },
  mounted() {
    this.loadData()
    EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Game.auditList(searchParams)
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.loading = false
    },
  },
}
</script>
